/*
#1F1F26
#303E8C
*/

body {
    font-family: "Open Sans";
}

a {
    color: inherit;
    text-decoration: none;
}

a.loginHeaderButton {
    border: solid 1px transparent;
}

a.loginHeaderButton:hover {
    border-bottom: solid 1px #DEB97C;
    border-bottom: solid 1px #889EDF;
    /* border-bottom: solid 1px #698EFF; */
}

.loginScreen {
    width: 100vw;
    max-width: 100%;
    display: grid;
    grid-template-rows: min-content 60vh 30vh 20vh;
    row-gap: 1rem;
    justify-content: center;
    background-image: linear-gradient(
        175deg,
        hsl(231deg 49% 37%) 0%,
        hsl(233deg 45% 35%) 1%,
        hsl(235deg 41% 33%) 6%,
        hsl(236deg 38% 32%) 21%,
        hsl(238deg 34% 30%) 48%,
        hsl(241deg 30% 28%) 69%,
        hsl(242deg 27% 27%) 82%,
        hsl(242deg 24% 26%) 90%,
        hsl(242deg 22% 25%) 95%,
        hsl(243deg 20% 24%) 98%,
        hsl(243deg 17% 23%) 100%,
        hsl(244deg 15% 22%) 100%
    );
}

.features {
    width: 80rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 1fr min-content 2fr;
    row-gap: 0.5rem;
    column-gap: 2rem;
}

.featureDisplay {
    display: grid;
    align-content: center;
    justify-content: center;
    border: solid 1px #889EDF;
    /* box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; */
}

.featureDisplayIcon {
    color: white;
    opacity: 80%;
    font-size: 2rem;
}

.featureDisplayTitle {
    color: white;
    font: 500 1.2rem "Open Sans";
}

.featureDisplayDescription {
    color: white;
    font: 300 1rem "Open Sans";
}

.loginHeader {
    display: flex;
    padding: 1rem 0;
    justify-content: center;
}

.loginHeaderContent {
    width: 80rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loginHeaderBranding {
    font: 600 1.5rem "Hind";
    color: white;
}

.loginHeaderMenu {
    display: flex;
    gap: 2rem;
    font: 300 1.2rem "Open Sans";
    color: white;
}

.loginScreenWelcome {
    display: grid;
    align-items: center;
    justify-items: center;
    color: white;
}

.loginScreenWelcomeText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    font: 300 3rem "Open Sans";
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

.loginScreenBranding {
    font-weight: 600;
    display: inline-block;
    animation: fadeIn ease 5s;
}

.loginScreenTagline {
    font: 300 1.5rem "Open Sans";
}

.loginScreenButtonBox {
    display: grid;
    align-items: flex-start;
    justify-items: center;
    color: white;
}

.chatUI {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-areas: "sidebar chatBox";
}

.chatBox {
    grid-area: chatBox;
    height: 100vh;
    display: grid;
    grid-template-rows: min-content auto min-content;
    grid-template-areas:
    "header"
    "chat"
    "sendDialog";
}

.sidebar {
    grid-area: sidebar;
    background-color: #302F40;
    color: white;
    font-family: "Open Sans";
}

.sidebar-section {
    border-top: solid 2px #1F1F26;
    margin-bottom: 1rem;
}

.sidebar-header {
    padding: 1rem 0.5rem;
}

.sidebarHeaderBranding {
    font: 600 1.5rem "Hind";
}

.sidebarHeaderStatus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font: 400 1rem "Open Sans";
}

.sidebarHeading {
    padding: 1rem;
    font: 300 1rem "Hind";
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sidebar-menu {
    font-size: 1rem;
}

.channelList {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
}

button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.channelItem {
    text-align: left;
    font: 500 1rem "Open Sans";
    padding: 0 1rem;
}

.sidebar-list {
    display: flex;
    flex-direction: column;
}

.sidebar-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font: 500 1rem "Open Sans";
    padding: 0 1rem;
}

.channelItem:hover {
    font-weight: 600;
}

.selected {
    background-color: #4369D9;
}

.chat {
    font-family: 'Open Sans';
    grid-area: chat;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    padding: 1rem;
}

.header {
    grid-area: header;
    background-color: #1F1F26;
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    padding: 0.5rem 2rem;
}

.headerText {
    justify-self: start;
}

.headerChannel {
    font: 600 1.5rem "Hind";
}

.headerChannelDescription {
    font: 300 1rem "Hind";
}

.headerMenu {
    justify-self: end;
}

.redDot {
    color: red;
    justify-self: end;
}

.greenDot {
    color: green;
    justify-self: end;
}

.sendDialog {
    /*
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    */
    grid-area: sendDialog;
    display: flex;
    padding: 1rem 2rem;
    border-top: solid 1px #e0e0e0;
}

.sendDialogInput {
    flex-grow: 1;
    border: 0 outset black;
}
.sendDialogInput:focus {
    outline: none;
}

.sendDialogButton {
    border: 1px outset black;
    background-color: white;
}

.messages {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
}

.messageCard {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.messageCardUserImage {
    max-height: 2.5rem;
    height: auto;
    display: block;
    object-fit: cover;
    border-radius: 0.5rem;
}

.messageCardContent {
    display: flex;
    flex-direction: column;
}

.messageCardHeader {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.user {
    font-size: 1rem;
    font-family: 'Hind';
    font-weight: 600;
}

.timestamp {
    font-size: 0.8rem;
    font-family: 'Hind';
    font-weight: 300;
    color: gray;
}

.messageBox {
    font-size: 1rem;
}

